import { Tooltip as ReactTooltip } from "react-tooltip";
import { v4 as uuidV4 } from "uuid";
import { cx } from "utils"

import { ReactComponent as InfoIcon } from "assets/icons/info-icon.svg";


interface TooltipProps {
  children: React.ReactNode;
  content: string | JSX.Element | React.ReactNode;
  className?: string;
}

export const Tooltip = ({ children, content, className }: TooltipProps) => {
  const tooltipID = uuidV4();

  return (
    <>
      <div className="flex gap-2 items-center">
        {children}
        <div data-tooltip-id={tooltipID} className={cx("h-fit", className)}>
          <InfoIcon className="w-[0.875rem] h-[0.875rem]" />
        </div>
      </div>
      <ReactTooltip
        id={tooltipID}
        className="!bg-brandDarkest !dark:bg-primary-800 !rounded-none max-w-sm [&>p>a]:underline"
        clickable
      >
        <p
          className="text-sm"
        >
          { content }
        </p>
      </ReactTooltip>
    </>
  );
};

export const globalTooltipClass = "show-tooltip";

// TODO this one still has some issues with observing DOM changes, so either a tooltip can remain visible even the 'globalTooltipClass' was removed from components, or will not show up
//  no matter the class has been added to components
export function GlobalTooltip() {
  return (
    <ReactTooltip
      // have to use anchorSelect here simply because using classes is way more reliable than using data-tooltip-id
      // tooltips can "stuck" after dynamically removing the data-tooltip-id attribute from elements
      // based on a very short experience there are no such bugs with adding and removing classes dynamically
      anchorSelect={`.${globalTooltipClass}`}
      className="!bg-brandDarkest !dark:bg-primary-800 !rounded-none max-w-sm [&>p>a]:underline"
    />
  );
}

export function PureTooltip({ children, content, className, clickable }: { clickable?: boolean } & TooltipProps) {
  const tooltipID = uuidV4();

  if (content) {
    return (
      <>
        <div data-tooltip-id={tooltipID} className={ className }>
          {children}
        </div>
        <ReactTooltip
          id={tooltipID}
          className="!bg-brandDarkest !dark:bg-primary-800 !rounded-none max-w-sm [&>p>a]:underline z-10 !text-sm"
          clickable={ clickable }
        >
          { content }
        </ReactTooltip>
      </>
    );
  } else {
    // no tooltip content
    return <div className={ className }>{ children }</div>;
  }
}
