import { ReactNode } from "react";

import { cx } from "utils";


export default function MaxWidthPage({ className, children }: { className?: string, children: ReactNode }) {
  return (
    <div className={ cx(className, "flex justify-center") }>
      <div className="max-w-[1400px]">
        { children }
      </div>
    </div>
  );
};
