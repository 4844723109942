import { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useFeatureSwitch } from "components/FeatureSwitch";

import { useSupportWidget } from "hooks/useSupportWidget";

import { useUser } from "routes/dataroutes/UserData";


export function useContactSales() {
  const user = useUser();
  const useNewSalesForm = useFeatureSwitch("REACT_APP_NEW_SALES_FORM_USERS");
  const location = useLocation();
  const navigate = useNavigate();
  const supportWidget = useSupportWidget();

  const callback = useCallback(() => {
    if (useNewSalesForm) {
      return navigate("/book-sales-call", { state: { backgroundLocation: location } });
    } else {
      return supportWidget?.showSalesForm(user?.email);
    }
  }, [ location, navigate, supportWidget, useNewSalesForm, user ]);

  return { contactSalesFn: callback };
}
