import { Params } from "react-router-dom";

import scraperApi, { ApiError, ApiErrorResponse, ChargebeeApiErrorResponse } from "api";

import { formDataToStructuredObject } from "utils/formDataUtils";
import IRouterActionError from "./IRouterActionError";
import OkResponse from "./OkResponse";


export async function cancelSubscriptionAction(): Promise<Response | IRouterActionError> {
  try {
    await scraperApi.subscription.cancel();
    return new OkResponse(); // all good
  } catch (error) {
    const err = error as any;
    return {
      error: {
        message: err.message || "Unexpected error when cancelling the subscription",
        meta: {
          shouldNotRetry: err.error_code === "user_outstanding_invoices"
        }
      }
    };
  }
}

export async function postCancellationSurveyAnswersAction({ request }: { request: Request }): Promise<Response | IRouterActionError> {
  const formDataObj = formDataToStructuredObject(await request.formData());
  try {
    await scraperApi.cancellationSurvey.saveAnswers(formDataObj);
  } catch (error) {
    // ignoring errors here, act as if everything was just fine letting users close the cancellation survey even if their answers haven't been saved
  }

  return new OkResponse();
}

export async function removeScheduledChangesAction(): Promise<Response | IRouterActionError> {
  try {
    await scraperApi.subscription.removeScheduledChanges();
    return new OkResponse();
  } catch (error) {
    return {
      error: {
        taggedMessage: {
          message: "There was an error processing your request. Please try again later or [contact our support team|contact_support]"
        }
      }
    };
  }
}

export async function billingAddressLoader() {
  const billingAddress = await scraperApi.subscription.billingAddress();
  return billingAddress || {};
}

export function billingAddressResetAction() {
  return null;
}

export async function paymentSourcesLoader() {
  const paymentSources = await scraperApi.subscription.paymentSources();
  return paymentSources || [];
}

export function paymentSourcesResetAction() {
  return null;
}

export async function couponsLoader({ request }: { request: Request }) {
  const url = new URL(request.url);
  const couponCode = url.searchParams.get("coupon-code");
  const targetPlanId = url.searchParams.get("target-plan-id");

  if (!couponCode) {
    // empty coupon code input, clear coupon data
    return {
      coupon_name: "",
      coupon_code: ""
    };
  }

  try {
    const couponResponse = await scraperApi.subscription.checkCoupons([ couponCode ], targetPlanId || undefined);
    return couponResponse[0];
  } catch (err) {
    return {
      coupon_code: couponCode,
      error: (err as Error).message
    };
  }

}

export async function upgradeSubscriptionAction({ request, params }: { request: Request, params: Params }): Promise<Response | IRouterActionError> {
  const formData = formDataToStructuredObject(await request.formData());

  // update payment source if that's part of the form data
  if (formData.payment_source) {
    try {
      await scraperApi.billing.setPaymentSource(formData.payment_source);
    } catch (err) {
      // TODO error handling
      const error = err as any;
      if ((error.error_code === "chargebee_error") && (error.details.chargebee_api_error_code === "payment_method_verification_failed")) {

        const errors: { [index: string]: string } = {};

        if ((error.details.chargebee_error_code === "add_card_error") && (error.details.message.toLowerCase().endsWith("expired card."))) {
          errors["expiry_year"] = "Your card is expired";
          return {
            error: {
              formInputErrors: errors
            }
          };
        }

        const invalidFieldMatcher = (error.details.message as string).match(/^card\[(\w+)] : (.*)/);
        if (invalidFieldMatcher && invalidFieldMatcher[1]) {
          if ((invalidFieldMatcher[1] === "expiry_month") && (error.details.chargebee_error_code === "not_in_allowed_range")) {
            errors["expiry_month"] = "Month is not in range";
          } else {
            errors[invalidFieldMatcher[1]] = invalidFieldMatcher[2] || "Invalid value";
          }
          return {
            error: {
              formInputErrors: errors
            }
          };
        }
      }

      // bubble up
      throw err;
    }
  }

  // update billing address if that's part of the form data
  if (formData.billing_address) {
    try {
      // passing the country code only
      formData.billing_address.country = formData.billing_address.country?.countryCode;
      await scraperApi.billing.setBillingAddress(formData.billing_address);
    } catch (err) {
      // TODO return detailed error message, so the billing address form can display that
      const error = err as any;
      if ((error.error_code === "chargebee_error") && (error.details.chargebee_api_error_code === "param_wrong_value")) {
        const invalidFieldMatcher = (error.details.message as string).match(/^billing_address\[(\w+)] : (.*)/);
        if (invalidFieldMatcher && invalidFieldMatcher[1]) {
          const errors: { [index: string]: string } = {};
          errors[invalidFieldMatcher[1]] = invalidFieldMatcher[2] || "Invalid value";
          return {
            error: {
              formInputErrors: errors
            }
          };
        }
      }

      // bubble up
      throw err;
    }
  }

  // payment source and billing address should be ok here
  try {
    await scraperApi.subscription.update(params.planId!, true, [ formData.coupon.coupon_code ], false);
  } catch (err) {
    const error = err as ApiError<ApiErrorResponse>;
    if (error.error_code === "chargebee_error") {
      const chargebeeError = error.details as ChargebeeApiErrorResponse;
      if (chargebeeError.chargebee_api_error_code === "payment_processing_failed") {
        return {
          // TODO both chargebeeError.message and chargebeeError.details hold useful information. we should somehow return both and display it to the user
          error: {
            taggedMessage: {
              message: chargebeeError.message || "Payment processing failed"
            }
          }
        }
      }
    }
    // TODO proper error handling here

    // bubble up
    throw err;
  }

  return new OkResponse();
}

export async function notPaidInvoicesLoader() {
  const notPaidInvoicesCount = await scraperApi.billing.invoices.getUnpaidInvoicesCount();
  return notPaidInvoicesCount || {};
}
