import { ReactComponent as CaretDownIcon } from "assets/icons/caret-down-icon.svg";
import { cx } from "utils";
// import { Transition } from "@headlessui/react";

export const listboxButtonClasses = "cursor-pointer px-2.5 py-2.5 bg-white border w-full  pr-10 text-left  relative border-lightGray dark:border-neutral-500 flex items-center gap-x-2 sm:text-sm";
export const placeHolderListboxButtonClasses = cx(listboxButtonClasses, "text-gray-200 dark:text-neutral-200");
export const listboxOptionsClasses = "absolute w-full py-1 mt-1 overflow-auto text-base bg-white shadow-lg ring-1 ring-lightGray dark:ring-neutral-500 focus:outline-none sm:text-sm z-50"
export const shortListboxOptionsClasses = cx(listboxOptionsClasses, "h-44");
export const shortListboxOptionsClassesOverflowing = cx(listboxOptionsClasses, "h-52");
export const listboxOptionClasses = "py-2.5 px-4 hover:bg-brandPrimary dark:hover:bg-primary-600 hover:text-white cursor-pointer transition z-50"
export const selectedListboxOptionClasses = "py-2.5 px-4 bg-brandPrimary dark:bg-primary-600 text-white cursor-pointer transition z-50"
export const placeHolderListboxOptionClasses = cx(listboxOptionClasses, "text-gray-200 dark:text-neutral-200");


export const listboxElementClasses = "flex flex-row items-center cursor-pointer select-none p-2 hover:bg-brandPrimary dark:hover:bg-primary-600 hover:text-white transition";
export const selectedListboxElementClasses = "flex flex-row items-center cursor-pointer select-none p-2 bg-brandPrimary dark:bg-primary-600 text-white transition";

export const ListboxButton = ({content}:{content: string|undefined, testId?: string }) => {
  return (<>
    {content}
    <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
      <CaretDownIcon className="w-2.5 h-2.5 text-black" />
    </span>
  </>);
}

export const ListboxElement = ({primaryText, secondaryText, selected = false}: {primaryText: string, secondaryText?: string|undefined, selected?: boolean}) => {
  const style = selected ? selectedListboxElementClasses : listboxElementClasses;
  return (
      <span className={style}>
        <span className="text-sm">{primaryText}</span>
        {secondaryText && <span className="text-sm ml-2 text-lightGray dark:text-neutral-500">{secondaryText}</span>}
      </span>
    );
}

// export const OptionsTransition = ({children}:{children: React.ReactNode}) => {
//   return (
//     <Transition
//       enter="transition duration-100 ease-out"
//       enterFrom="transform scale-95 opacity-0"
//       enterTo="transform scale-100 opacity-100"
//       leave="transition duration-75 ease-out"
//       leaveFrom="transform scale-100 opacity-100"
//       leaveTo="transform scale-95 opacity-0"
//     >
//       {children}
//     </Transition>);
// }

export const OptionsTransition = ({children}:{children: React.ReactNode}) => {
  return (
    <div >
      {children}
    </div>);
}
