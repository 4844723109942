import React, { ReactNode } from 'react';
import { cx } from 'utils';

import { Size, Sizes } from 'styles/sizes';

type CardSizes = Sizes<string>;

const cardSizes: CardSizes = {
  SM: "p-3 gap-y-3",
  MD: "p-4 gap-y-4",
  LG: "p-6 gap-y-6",
};

interface CardProps {
  title?: string | ReactNode;
  extra?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  className?: string;
  size?: Size;
  hoverable?: boolean;
  segmented?: boolean;
}

const Card: React.FC<CardProps> = ({
  title,
  extra,
  children,
  footer,
  className,
  size = "MD",
  hoverable = false,
  segmented = false,
}) => {
  const sizeClass = cardSizes[size];

  return (
    <div
      className={cx(
        "dark:rounded-md border border-neutral-200",
        hoverable && "transition-colors duration-200 dark:hover:bg-accent-50/50",
        "flex flex-col",
        sizeClass,
        className
      )}
    >
      {(title || extra) && (
        <div className={cx(
          "flex justify-between items-center",
          segmented && "border-b border-neutral-100"
        )}>
          {title && <h2 className="text-lg font-medium">{title}</h2>}
          {extra && <div>{extra}</div>}
        </div>
      )}
      <div>{children}</div>
      {footer && (
        <div>{footer}</div>
      )}
    </div>
  );
};

export default Card;
