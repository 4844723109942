import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import { Transition } from "@headlessui/react";
import { RiCoinsLine, RiMenuFoldLine, RiMenuUnfoldLine } from "@remixicon/react";

import scraperApi from "api";
import { cx } from "utils";
import { getUserInitials } from "utils/userUtils";

import { ReactComponent as MobileMenuIcon } from "assets/icons/menu-icon.svg";
import { ReactComponent as ScraperAPILogo } from "assets/icons/scraperapi-logo.svg";
import { ReactComponent as DashboardIcon } from "assets/icons/dashboard-icon.svg";
import { ReactComponent as BillingIcon } from "assets/icons/billing-icon.svg";
import { ReactComponent as ScrapingIllustration } from "assets/images/scraperapi-illustration2.svg";
import { ReactComponent as PulseIcon } from "assets/icons/pulse-icon.svg";
import { ReactComponent as LifebuoyIcon } from "assets/icons/lifebuoy-icon.svg";
import { ReactComponent as DocumentIcon } from "assets/icons/document-icon.svg";
import { ReactComponent as HeadsetIcon } from "assets/icons/headset-icon.svg";
import { ReactComponent as ApiPlaygroundIcon } from "assets/icons/api-playground.svg";
import { ReactComponent as DataPipelineIcon } from "assets/icons/data-pipeline.svg";

import Button from "components/Button";
import { useFeatureSwitch } from "components/FeatureSwitch";
import { FeatureSwitch } from "components/FeatureSwitch/FeatureSwitches";
import { UserBadge, UserMenuButtonControlled, UserMenuControlled } from "components/UserMenu";
import TaggedText from "components/TaggedText";

import { useContactSales } from "hooks/useContactSales";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useSupportWidget } from "hooks/useSupportWidget";

import { useUserProvider } from "providers/UserProvider";

import { useUser } from "routes/dataroutes/UserData";


const PANEL_WIDTH = "w-[256px]";
const COLLAPSED_WIDTH = "w-[64px]";
const USER_MENU_WIDTH = "w-[270px]";


interface ISidebarItemProps {
  Icon?: React.ComponentType<any>;
  iconStyle?: string;
  icon?: JSX.Element;
  content?: JSX.Element | string;
  contentPadding?: string;
  collapsed?: boolean;
  disabled?: boolean;
  disableWhenBlocked?: boolean;
  active?: boolean;
  onClick?: () => void;
  featureSwitch?: FeatureSwitch;
}

function SidebarItemContent({ Icon, iconStyle, icon, content, contentPadding = "py-3 pr-4", collapsed, active }: ISidebarItemProps) {
  return (
    <>
      { (Icon || icon) && (
        <div
          className={ cx("pl-6 h-12 flex place-content-center items-center border-l-[3px] dark:border-l-0", active ? "border-l-brandPrimary" : "border-l-transparent") }
        >
          { Icon && <Icon className={ iconStyle } /> }
          { icon }
        </div>
      ) }
      <div className={ cx(
        "w-full font-droid",
        contentPadding,
        collapsed && "*:transition-opacity *:opacity-0 *:duration-700"
        ) }
      >
        { typeof content === "string" ? <div className="pl-4 w-full whitespace-nowrap">{ content }</div> : <>{ content }</> }
      </div>
    </>
  );
}

function SidebarItem({ Icon, iconStyle, icon, content, contentPadding, collapsed }: ISidebarItemProps) {
  return (
    <div className="flex flex-row items-center w-full">
      <SidebarItemContent
        Icon={ Icon }
        iconStyle={ iconStyle }
        icon={ icon }
        content={ content }
        contentPadding={ contentPadding }
        collapsed={ collapsed }
      />
    </div>
  );
}

function useReallyDisabled(disabled?: boolean, disableWhenBlocked?: boolean) {
  const user = useUser();
  return disabled || (disableWhenBlocked && user?.isBlocked);
}

function SidebarNavLink({ disabled, disableWhenBlocked, to, onClick, addLocationToState, featureSwitch, ...props }: ISidebarItemProps & { to: string, addLocationToState?: boolean }) {
  const location = useLocation();
  const isFeatureEnabled = useFeatureSwitch(featureSwitch);
  const reallyDisabled = useReallyDisabled(disabled, disableWhenBlocked);

  if (!isFeatureEnabled) {
    return <></>;
  }

  return (
    <NavLink
      to={ to }
      className={ ({ isActive }) => cx(
        "flex flex-row items-center w-full transition-colors duration-50",
        (!reallyDisabled && isActive) ? "text-brandPrimary dark:text-white dark:bg-primary-600" : "hover:bg-slate-50 dark:hover:bg-primary-100 hover:text-gray-700 dark:hover:text-neutral-900"
      ) }
      onClick={ onClick }
      state={ { backgroundLocation: addLocationToState ? location : undefined } }
    >
      { ({ isActive }) => (
        <SidebarItemContent
          { ...props }
          active={ isActive }
        />
      )}
    </NavLink>
  );
}

function SidebarExtLink({ disabled, disableWhenBlocked, href, onClick, ...props }: ISidebarItemProps & { href: string }) {
  const reallyDisabled = useReallyDisabled(disabled, disableWhenBlocked);

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      href={ href }
      className={ cx(
        "flex flex-row items-center w-full",
        !reallyDisabled && "hover:bg-slate-50 dark:hover:bg-primary-100 hover:text-gray-700 dark:hover:text-neutral-900"
      )}
      onClick={ onClick }
    >
      <SidebarItemContent
        { ...props }
      />
    </a>
  )
}

function SidebarAction({ disabled, disableWhenBlocked, onClick, ...props }: ISidebarItemProps) {
  const reallyDisabled = useReallyDisabled(disabled, disableWhenBlocked);

  return (
    <div
      className={ cx(
        "flex flex-row items-center w-full cursor-pointer",
        !reallyDisabled && "hover:bg-slate-50 dark:hover:bg-primary-100 hover:text-gray-700"  // TODO do not change text color in new design (dark mode)
      ) }
      onClick={ onClick }
    >
      <SidebarItemContent { ...props } />
    </div>
  );
}

const UpgradeButton = ({ onClickCallback }: { onClickCallback?: () => void }) => {

  const showNewSalesBanner = useFeatureSwitch("REACT_APP_NEW_SALES_FORM_USERS");
  const { subscription } = useUserProvider();

  if (subscription?.plan_id !== "free") {
    return <></>;
  }

  if (showNewSalesBanner) {
    return (
      <div className="flex flex-col items-center justify-center ml-4 my-4 p-4 space-y-4 bg-brandLightest dark:bg-primary-50">
        <p className="text-sm text-brandDarkest dark:text-primary-800 text-center max-w-[220px] mb-2">
          <TaggedText message="Need more time to test and maximize your experience? [Book a call|book_sales_call] and get an extended trial!" />
        </p>
        <Button
          text="Book a call"
          className="button button-primary"
          size="MD"
          href="/book-sales-call"
          onClick={ onClickCallback }
          focusable={ false }
        />
      </div>
    );
  } else {
    return (
      <div className="flex space-x-2 ml-4 my-4 p-3 rounded-md bg-primary-600 dark:bg-primary-100 dark:border dark:border-primary-200">
        <ScrapingIllustration className="w-10 h-10 p-1 rounded dark:bg-primary-600"/>
        <div>
          <div className="text-xs font-medium text-gray-900 dark:text-neutral-900">Need more credits?</div>
          <Button
            text="Upgrade account"
            uppercase={false}
            className="button button-text-secondary"
            href="/billing"
            onClick={ onClickCallback }
            size="SM"
          />
        </div>
      </div>
    );
  }
};

function StatusBadge({ xOffset, yOffset, className }: { xOffset: string, yOffset: string, className?: string }) {
  const [ status, setStatus ] = useState<Status>();
  const fetchStatus = useCallback(async (controller: AbortController) => {
    const status = await scraperApi.status.issues({ signal: controller.signal });
    setStatus(status);
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    fetchStatus(controller);

    return () => controller.abort();
  }, [ fetchStatus ]);

  return (
    <Transition
      show={ ((status?.numActiveIssues || 0) > 0) }
      enter="transition-all ease-in-out duration-100"
      enterFrom="opacity-0 scale-50"
      enterTo="opacity-100 scale-100"
      leave="transition-all ease-in-out duration-100"
      leaveFrom="opacity-100 scale-100"
      leaveTo="opacity-0 scale-50"
    >
      <div className={ cx(
        "flex items-center justify-center w-4 h-4 text-xs text-white bg-brandPrimary dark:bg-warning-600 rounded-full absolute",
        xOffset,
        yOffset,
        className
      ) }>
        { status?.numActiveIssues }
      </div>
    </Transition>
  );
}

interface Status {
  mostRecentIssue: any;
  numActiveIssues: number;
  numRecentlyResolvedIssues: number;
}

interface ILogoAndIconsProps {
  mobile?: boolean;
  toggleMobileMenu?: () => void;
  collapsed?: boolean;
  toggleCollapsed?: () => void;
  showCollapseIcon?: boolean;
}

function LogoAndIcons({ mobile, toggleMobileMenu, collapsed, toggleCollapsed, showCollapseIcon = true }: ILogoAndIconsProps) {
  return (
    <>
      {mobile && (
        <div className="flex items-center justify-between">
          <ScraperAPILogo />
          <MobileMenuIcon
            className="cursor-pointer text-lightGray dark:text-neutral-500 hover:text-gray dark:hover:text-neutral-600 transition-colors"
            onClick={toggleMobileMenu}
          />
        </div>
      )}

      {!mobile && (
        <div className={cx("flex items-center", collapsed ? "justify-center" : "justify-between")}>
          {collapsed ? (
            <RiMenuUnfoldLine
              className="cursor-pointer text-lightGray dark:text-neutral-300 hover:text-gray dark:hover:text-neutral-400 duration-200 transition-colors"
              onClick={toggleCollapsed}
            />
          ) : (
            <>
              <ScraperAPILogo className="h-6 w-auto flex-shrink-0" />
              {showCollapseIcon && (
                <RiMenuFoldLine
                  className="cursor-pointer text-lightGray dark:text-neutral-300 hover:text-gray dark:hover:text-neutral-400 duration-200 transition-colors"
                  onClick={toggleCollapsed}
                />
              )}
            </>
          )}
        </div>
      )}
    </>
  );
}

function ContactSupportSidebarAction({ collapsed }: { collapsed: boolean }) {
  const supportWidget = useSupportWidget();
  const user = useUser();

  return (
    <SidebarAction
      content="Contact Support"
      Icon={ LifebuoyIcon }
      iconStyle="w-5 dark:w-4 h-5 dark:h-4"
      collapsed={ collapsed }
      onClick={ () => {
        supportWidget?.showSupportForm(user?.email);
      } }
    />
  );
}

function ContactSalesSidebarAction({ collapsed }: { collapsed: boolean }) {
  const { contactSalesFn } = useContactSales();

  return (
    <SidebarAction
      content="Contact Sales"
      Icon={ HeadsetIcon }
      iconStyle="w-5 dark:w-4 h-5 dark:h-4"
      collapsed={ collapsed }
      onClick={ contactSalesFn }
    />
  );
}

function UserMenuSection({ collapsed }: { collapsed: boolean }) {
  const user = useUser();
  const [showUserMenu, setShowUserMenu] = useState(false);

  return (
    <div onClick={() => setShowUserMenu(x => !x)} className="px-4 py-4 hover:bg-slate-50 dark:hover:bg-neutral-100">
      <div
        className={
          "flex items-center cursor-pointer space-x-2"}
      >
        <UserBadge initial={getUserInitials(user)} />
        {!collapsed && <UserMenuButtonControlled open={showUserMenu} />}
      </div>
      <div className={cx(collapsed && "*:z-40 z-40")}>
        <UserMenuControlled showMenu={showUserMenu} close={ (event?: MouseEvent) => { event?.stopPropagation(); setShowUserMenu(false); } } widthClassName={USER_MENU_WIDTH} />
      </div>
    </div>
  );
}

const WrapForMobile = ({ mobile, show, mobileMenuToggleCallback, children }: { mobile: boolean, show: boolean, mobileMenuToggleCallback: () => void, children: React.ReactNode }) => {
  const refContent = useRef<any | null>(null);
  const refHeader = useRef<any | null>(null);
  useEffect(() => {
    if (mobile && show) {
      const handleClick = (e: MouseEvent) => {
        const inContent = refContent.current && refContent.current.contains(e.target as Node);
        const inHeader = refHeader.current && refHeader.current.contains(e.target as Node);
        if (!inHeader && !inContent) {
          mobileMenuToggleCallback();
        }
      };
      // Don't use 'click' here. inHeader will be always false. The ref won't contain the target.
      document.addEventListener('mousedown', handleClick);
      return () => document.removeEventListener('mousedown', handleClick);
    }
  }, [ show, mobile, mobileMenuToggleCallback ]);

  if (mobile) {
    return (
      <>
        <div ref={refHeader} className="select-none border-b border-borderColor dark:border-neutral-200 px-6 pt-3 pb-4 border-l-[3px] border-l-transparent">
          <LogoAndIcons mobile toggleMobileMenu={mobileMenuToggleCallback}/>
        </div>

        <div ref={refContent}>
          <Transition
            show={ show }
            enter="transition-transform ease-in-out duration-500"
            enterFrom="-translate-x-full"
            enterTo="translate-x-0"
            leave="transition-transform ease-in-out duration-500"
            leaveFrom="translate-x-0"
            leaveTo="-translate-x-full"
          >
            <div className="absolute top-0 left-0 h-full overflow-y-auto z-10">
              { children }
            </div>
          </Transition>
        </div>
      </>
    );
  } else {
    return <>{ children }</>;
  }
};

export default function Sidebar() {
  const [ collapsed, setCollapsed ] = useLocalStorage("saSidebarCollapsed", false);
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });
  const [ showMobileMenu, setShowMobileMenu ] = useState(false);

  const reallyCollapsed = useMemo(() => collapsed && !isMobile, [ collapsed, isMobile ]);

  return (
    <WrapForMobile mobile={ isMobile } show={showMobileMenu} mobileMenuToggleCallback={() => setShowMobileMenu(x => !x)}>
      <div className={ cx("relative h-full group *:h-full text-gray dark:text-neutral-600", reallyCollapsed && COLLAPSED_WIDTH) }>
      <div className={cx(
  "border-r border-borderColor dark:border-neutral-200 flex flex-col justify-between transition-all duration-300 ease-in-out dark:bg-neutral-50",
  reallyCollapsed ? `${COLLAPSED_WIDTH}` : `${PANEL_WIDTH}`
)}>
            <div className="space-y-8">
              <div className="px-4 pt-4">
                <LogoAndIcons
                  showCollapseIcon={!isMobile}
                  toggleCollapsed={() => setCollapsed(c => !c)}
                  collapsed={reallyCollapsed}
                />
              </div>

            <div className="space-y-2">
            <SidebarNavLink
              to="/"
              content="Dashboard"
              Icon={ DashboardIcon }
              iconStyle="w-5 dark:w-4 h-5 dark:h-4"
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />

            <SidebarNavLink
              featureSwitch="REACT_APP_API_PLAYGROUND_USERS"
              to="/apiplayground"
              content="API playground"
              Icon={ ApiPlaygroundIcon }
              iconStyle="w-5 dark:w-4 h-5 dark:h-4"
              disableWhenBlocked
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />

            <SidebarNavLink
              to="/projects"
              content="DataPipeline"
              Icon={ DataPipelineIcon }
              iconStyle="w-5 dark:w-4 h-5 dark:h-4"
              disableWhenBlocked
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />

            <SidebarNavLink
              to="/billing"
              content="Billing"
              Icon={ BillingIcon }
              iconStyle="w-5 dark:w-4 h-5 dark:h-4"
              collapsed={ reallyCollapsed }
              onClick={() => {setShowMobileMenu(c => !c); }}
            />
          </div>
          </div>

          <div className="relative">
            <SidebarItem
              disabled
              collapsed={ reallyCollapsed }
              content={ (
                <div className={ cx("transition-all origin-left duration-1000", reallyCollapsed ? "-translate-x-full invisible" : "translate-x-0") }>
                  <UpgradeButton />
                </div>
              ) }
            />

            <div className="space-y-2 pb-4">
              <SidebarExtLink
                href="https://status.scraperapi.com"
                content={ <div className="flex flex-row gap-x-3 relative">
                  <div className="pl-4 whitespace-nowrap">Status Page</div>
                  { !reallyCollapsed && <StatusBadge xOffset="left-[108px]" yOffset="top-[-10px]" /> }
                </div> }
                icon={ <>
                  <PulseIcon className="relative w-5 dark:w-4 h-5 dark:h-4" />
                  { reallyCollapsed && <StatusBadge xOffset="right-[-14px]" yOffset="top-0" /> }
                  </> }
                collapsed={ reallyCollapsed }
              />

              <SidebarExtLink
                href="https://www.scraperapi.com/documentation/"
                content="Documentation"
                Icon={ DocumentIcon }
                iconStyle="w-5 dark:w-4 h-5 dark:h-4"
                collapsed={ reallyCollapsed }
              />

              <SidebarExtLink
                href="https://www.scraperapi.com/affiliates/"
                content="Referral program"
                Icon={ RiCoinsLine }
                iconStyle="w-5 dark:w-4 h-5 dark:h-4"
                collapsed={ reallyCollapsed }
              />

              <ContactSupportSidebarAction collapsed={ reallyCollapsed } />

              <ContactSalesSidebarAction collapsed={ reallyCollapsed } />
            </div>

            <SidebarItem
              content={ <div className="flex flex-row items-center">
                <div className="border-t border-borderColor dark:border-neutral-200 w-full"/>
              </div> }
              contentPadding=""
              disabled
            />

            <UserMenuSection collapsed={reallyCollapsed} />
          </div>
        </div>
      </div>
    </WrapForMobile>
  );
}
