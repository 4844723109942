import { AsyncUrls } from "./urls";
import { AmazonProductContent } from "./amazon-product"
import { AmazonSearchContent } from "./amazon-search"
import { UknownContent } from "./unknown";
import { ProjectContentAP, ProjectContentHS, SDEContent, ProjectIcon } from "./sdecontentTypes";
import { CollectorType } from "providers/HostedScrapingProvider";
import { AmazonOfferContent } from "./amazon-offer";
import { AmazonReviewContent } from "./amazon-review";
import { GoogleJobsContent } from "./google-jobs";
import { GoogleNewsContent } from "./google-news";
import { GoogleSearchContent } from "./google-search";
import { GoogleShoppingContent } from "./google-shopping";
import { WalmartProductContent } from "./walmart-product";
import { WalmartCategoryContent } from "./walmart-category";
import { WalmartSearchContent } from "./walmart-search";
import { WalmartReviewContent } from "./walmart-review";
import { EbayProductContent } from "./ebay-product";


const allProjectDescriptors = [
  AsyncUrls,
  AmazonProductContent, AmazonSearchContent, AmazonOfferContent, AmazonReviewContent,
  GoogleJobsContent, GoogleNewsContent, GoogleSearchContent, GoogleShoppingContent,
  WalmartProductContent, WalmartCategoryContent, WalmartSearchContent, WalmartReviewContent,
  EbayProductContent
];

export const validCollectorTypes: CollectorType[] = allProjectDescriptors.map(projectType => projectType.projectType);

export const getSDEDescriptorForProjectType = (projectType: CollectorType | undefined): SDEContent => {
  const result = allProjectDescriptors.find(sde => sde.projectType === projectType);
  return result ?? UknownContent;
}

export const isAsyncUrlsProject = (projectType: CollectorType): boolean => getSDEDescriptorForProjectType(projectType).projectCategory === 'async_urls';
export const isAmazonProject = (projectType: CollectorType): boolean => getSDEDescriptorForProjectType(projectType).projectCategory === 'amazon';
export const isGoogleProject = (projectType: CollectorType): boolean => getSDEDescriptorForProjectType(projectType).projectCategory === 'google';
export const isWalmartProject = (projectType: CollectorType): boolean => getSDEDescriptorForProjectType(projectType).projectCategory === 'walmart';
export const isEbayProject = (projectType: CollectorType): boolean => getSDEDescriptorForProjectType(projectType).projectCategory === 'ebay';

export const isSDEProject = (projectType: CollectorType): boolean => getSDEDescriptorForProjectType(projectType).projectCategory !== 'async_urls';

export const sdeDescriptors = allProjectDescriptors.filter((type) => (type.projectType !== 'async_urls'))
export const validSDECollectorTypes = validCollectorTypes.filter((type) => (type !== 'async_urls'))


// Validators ---------------------------------------------

export const inputValidatorFn = (projectType: string) => getSDEDescriptorForProjectType(projectType).validatorFunction;


// Project edit page ---------------------------------------------

export const projectTypeToTitleAndDescriptionHS = (projectType: CollectorType|undefined) => getSDEDescriptorForProjectType(projectType).newProjectEditProject.projectContent;

export const getInputBoxLabelsHS = (projectType: CollectorType|undefined) => getSDEDescriptorForProjectType(projectType).newProjectEditProject.inputBoxLabels;

export const getInputSectionLabelsHS = (projectType: CollectorType|undefined) => getSDEDescriptorForProjectType(projectType).newProjectEditProject.inputSectionLabels;

export const projectTypeToInputTitleHS = (projectType: CollectorType|undefined) => getSDEDescriptorForProjectType(projectType).newProjectEditProject.projectContent.inputTitle;

export const projectTypeHS = (projectType: CollectorType|undefined) => getSDEDescriptorForProjectType(projectType).newProjectEditProject.projectContent.title;

export const iconTypeHS = (projectType: CollectorType|undefined) => getSDEDescriptorForProjectType(projectType).newProjectEditProject.projectContent.icon;


// API playground ---------------------------------------------

export const projectTypeToTitleAndDescriptionAP = (projectType: CollectorType|undefined) => getSDEDescriptorForProjectType(projectType).apiPlayground.projectContent;

export const getInputSectionLabelsForAP = (projectType: CollectorType|undefined) => getSDEDescriptorForProjectType(projectType).apiPlayground.inputSectionLabels;

export const projectTypeAP = (projectType: CollectorType|undefined) => getSDEDescriptorForProjectType(projectType).apiPlayground.projectContent.title;

export const iconTypeAP = (projectType: CollectorType|undefined) => getSDEDescriptorForProjectType(projectType).apiPlayground.projectContent.icon;

export const enterInputsMessageAP = (projectType: CollectorType | undefined) => getSDEDescriptorForProjectType(projectType).apiPlayground.enterInputsMessage;

export const enterInputsPlaceholderAP = (projectType: CollectorType | undefined) => getSDEDescriptorForProjectType(projectType).apiPlayground.enterInputsPlaceholder;

export const codeViewPlaceHolderAP = (projectType: CollectorType | undefined) => getSDEDescriptorForProjectType(projectType).apiPlayground.codeViewPlaceHolder;

// Common -----------------------------------------------------

export const collectorTypeToInputFormat = (type: CollectorType, inputLength: number): string => {
  const descriptor = getSDEDescriptorForProjectType(type);
  if (inputLength === 1) {
    return descriptor.inputKey;
  } else {
    return descriptor.inputsKey;
  }
}

export const collectorTypeToAPIURL = (projectType: CollectorType) => getSDEDescriptorForProjectType(projectType).apiUrlFn;

export type ProjectVariant = 'hosted-scraper' | 'api-playground';

export const projectType = (variant: ProjectVariant, configType: CollectorType): string => {
  switch (variant) {
    case 'hosted-scraper': return projectTypeHS(configType);
    case 'api-playground': return projectTypeAP(configType);
  }
};

export const iconType = (variant: ProjectVariant, configType: CollectorType): ProjectIcon => {
  switch (variant) {
    case 'hosted-scraper': return iconTypeHS(configType);
    case 'api-playground': return iconTypeAP(configType);
  }
};

export const projectTypeToTitleAndDescription = (variant: ProjectVariant, projectType: CollectorType): ProjectContentHS | ProjectContentAP => {
  switch (variant) {
    case 'hosted-scraper': return projectTypeToTitleAndDescriptionHS(projectType);
    case 'api-playground': return projectTypeToTitleAndDescriptionAP(projectType);
  }
};
